const DEVELOPMENT = false;

//import { SatelliteSharp } from "@mui/icons-material"
//export const API_BASE_URL="https://incidents.microlyth.com/api/v1"
//export const API_BASE_URL="http://ec2-18-212-73-149.compute-1.amazonaws.com:3000/api/v1"
export const BASE_URL = DEVELOPMENT ? "http://localhost:3000" : "https://api.tasklogger.io";
//export const BASE_URL = "https://api.tasklogger.io";

export const API_BASE_URL = `${BASE_URL}/api/v1`;
export const FRONTEND_BASE_URL = DEVELOPMENT ? "http://localhost:3001" : "https://tasklogger.io";
//  "http://bimlog-fe-s3.s3-website.eu-north-1.amazonaws.com";

//export const API_BASE_URL = "http://10.10.20.203:3000/api/v1";
//export const API_BASE_URL = "http://85.195.82.203:3000/api/v1";
export const MOCK_API_BASE_URL =
  "https://043fba4e-6493-4a75-a231-8593be9ae428.mock.pstmn.io";

const STATIC_BASE_URL = "https://tasklogger-uploads.s3.eu-north-1.amazonaws.com";

export const UPLOAD_BASE_URL = API_BASE_URL + "/" + "uploads";

export const MARGIN_VALUE = "none";
export const VARIANT_VALUE = "outlined";
export const SIZE_VALUE = "small";

export const CUSTOM_THEME = {
  COLORS: {
    NOTIFICATION: "#F4CE14",
    FOCUS: "#A8A196",
    HOVER: "#2192FF",
    CHECKBOX: "#2192FF",
    ACTION: "#3EC70B",
    HISTORY: "#952323",
    MESSAGE: "#2192FF",
    BORDER: "rgba(0, 0, 0, 0.23)",
  },
  FAB_RIGHT_POSITION: "42px",
  CHECKBOX_FONT_SIZE: ".8rem",
};

export const DEFAULT_SETTINGS = {
  theme: {},
  font: {},
  input: {
    variant: "outlined",
    size: "small",
    margin: "none",
  },
  date_format: "dd-MM-yyyy",
};

export const COLOR_PALETTES_LIST = [
  {
    value: 0,
    label: "Default",
  },
  {
    value: 1,
    label: "BIMPOS",
  },
  {
    value: 2,
    label: "Blue-Green",
  },
  {
    value: 3,
    label: "Teal-Orange",
  },
  {
    value: 4,
    label: "Purple-Orange",
  },
  {
    value: 5,
    label: "Earthy Tones",
  },
  {
    value: 6,
    label: "Neutral Grays",
  },
  {
    value: 7,
    label: "Oceanic Blues",
  },
];

export const COLOR_PALETTES_ENUM = {
  0: {},
  1: {
    primary: {
      main: "#f89424",
      light: "#f89424",
      dark: "#f89424",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#21130d",
      light: "#21130d",
      dark: "#21130d",
      contrastText: "#FFFFFF",
    },
  },
  2: {
    primary: {
      main: "#2196F3",
      light: "#64B5F6",
      dark: "#1565C0",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#4CAF50",
      light: "#81C784",
      dark: "#388E3C",
      contrastText: "#FFFFFF",
    },
  },
  3: {
    primary: {
      main: "#009688",
      light: "#4DB6AC",
      dark: "#00796B",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF9800",
      light: "#FFB74D",
      dark: "#F57C00",
      contrastText: "#FFFFFF",
    },
  },
  4: {
    primary: {
      main: "#673AB7",
      light: "#9575CD",
      dark: "#512DA8",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF5722",
      light: "#FF8A65",
      dark: "#E64A19",
      contrastText: "#FFFFFF",
    },
  },
  5: {
    primary: {
      main: "#546E7A", // Slate Blue
      light: "#90A4AE", // Blue Grey
      dark: "#263238", // Dark Blue Grey
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF7043", // Deep Orange
      light: "#FFA270", // Light Deep Orange
      dark: "#E64A19", // Dark Deep Orange
      contrastText: "#FFFFFF",
    },
  },
  6: {
    primary: {
      main: "#607D8B", // Blue Grey
      light: "#90A4AE", // Blue Grey
      dark: "#455A64", // Dark Blue Grey
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFB74D", // Amber
      light: "#FFCC80", // Light Amber
      dark: "#FFA726", // Dark Amber
      contrastText: "#FFFFFF",
    },
  },
  7: {
    primary: {
      main: "#1976D2", // Royal Blue
      light: "#64B5F6", // Light Blue
      dark: "#0D47A1", // Dark Blue
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFA726", // Amber
      light: "#FFCC80", // Light Amber
      dark: "#FF8F00", // Dark Amber
      contrastText: "#FFFFFF",
    },
  },
};

export const CUSTOMER_PAGE_TAB_VALUES = {
  "general-information": 0,
  details: 1,
  events: 2,
  "other-information": 3,
  actions: 4,
};

export const USER_PAGE_TAB_VALUES = {
  "general-information": 0,
  "permissions": 1,
  "email-notification": 2,
  settings: 3,
  "data-export": 4,
};

export const ENUM_LOG_ACTION_TYPE = {
  1: "MESSAGE",
  2: "HISTORY",
  3: "ACTION",
};

export const ENUM_LOG_ACTION_TYPE_Id = {
  MESSAGE: 1,
  HISTORY: 2,
  ACTION: 3,
};

export const ENUM_LOG_ACTION_SOURCE_TYPE_Id = {
  bimAll: 0,
  bimTasks: 1,
  bimLogs: 2,
};

export const MENTION_TYPES = {
  ALL: -1,
  ALLFOLLOWERS: -2,
};

export const ENUM_LOG_FAST_ACTION_TYPE = {
  0: "Phone call",
  1: "Visit",
  2: "No action",
  3: "In person",
  4: "Remotly",
  5: "Email",
  6: "WhatsApp",
};

export const ENUM_LOG_FAST_ACTION_TYPE_Id = {
  "Phone call": 0,
  Visit: 1,
  "No action": 2,
  "In person": 3,
  Remotly: 4,
  Email: 5,
  WhatsApp: 6,
};

export const LIST_LOG_FAST_ACTION_TYPE = [
  {
    value: 0,
    label: "Phone call",
  },
  {
    value: 1,
    label: "Visit",
  },
  {
    value: 2,
    label: "No action",
  },
  {
    value: 3,
    label: "In person",
  },
  {
    value: 4,
    label: "Remotly",
  },
  {
    value: 5,
    label: "Email",
  },
  {
    value: 6,
    label: "WhatsApp",
  },
];

export const LIST_ACTION_LOCATION = [
  { id: 1, description: "In person" },
  { id: 2, description: "In system" },
];

export const ENUM_LOG_ACTION_SOURCE_TYPE = {
  0: "BIM-ALL",
  1: "BIM-TASKS",
  2: "BIM-LOGS",
};

export const ENUM_LOG_STATUS = {
  0: "Unknown",
  1: "Pending",
  2: "Completed",
  3: "Paused",
};

export const LIST_LOG_STATUS = [
  {
    value: "",
    label: "Choose Status",
  },
  {
    value: 1,
    label: "Pending",
  },
  {
    value: 2,
    label: "Completed",
  },
  {
    value: 3,
    label: "Paused",
  },
];

export const ENUM_LOG_TYPES = {
  1: "Unknown",
  2: "Software",
  3: "Hardware",
  4: "Request",
  5: "Sales",
  6: "Software Request",
  7: "Software Bug",
  8: "Report Request",
  9: "QA / Software Testing",
  10: "Documentation",
  11: "Customer Software Audit",
  12: "Report Bug",
  13: "Internal QA",
};

export const LIST_LOG_TYPES = [
  {
    value: "",
    label: "Choose Type",
  },
  {
    value: 1,
    label: "Unknown",
  },
  {
    value: 2,
    label: "Software",
  },
  {
    value: 3,
    label: "Hardware",
  },
  {
    value: 4,
    label: "Request",
  },
  {
    value: 5,
    label: "Sales",
  },
  {
    value: 6,
    label: "Software Request",
  },
  {
    value: 7,
    label: "Software Bug",
  },
  {
    value: 8,
    label: "Report Request",
  },
  {
    value: 9,
    label: "QA / Software Testing",
  },
  {
    value: 10,
    label: "Documentation",
  },
  {
    value: 11,
    label: "Customer Software Audit",
  },
  {
    value: 12,
    label: "Report Bug",
  },
  {
    value: 13,
    label: "Internal QA",
  },
];

export const ENUM_ACTION_TYPE = {
  0: "Remark",
  1: "Call",
  2: "Visit",
  3: "Email",
};

export const LIST_ACTION_TYPE = [
  {
    value: "",
    label: "Choose Type",
  },
  {
    value: 0,
    label: "Remark",
  },
  {
    value: 1,
    label: "Call",
  },
  {
    value: 2,
    label: "Visit",
  },
  {
    value: 3,
    label: "Email",
  },
];

export const ENUM_TASK_STATUS = {
  DONE: "Done",
  REVIEW: "Review",
  INPROGRESS: "In Progress",
  NOTSTARTED: "Not Started",
};

export const LIST_TASK_STATUS = [
  {
    value: "",
    label: "Choose Status",
  },
  {
    value: "not_started",
    label: "Not Started",
  },
  {
    value: "in_progress",
    label: "In Progress",
  },
  {
    value: "review",
    label: "Review",
  },
  {
    value: "done",
    label: "Done",
  },
];

export const ENUM_PRIORITY_VALUE = {
  1: "High",
  2: "Normal",
  3: "Low",
};

export const LIST_PRIORITY_VALUE = [
  {
    value: "",
    label: "Choose Priority",
  },
  {
    value: "high",
    label: "High",
  },
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "low",
    label: "Low",
  },
];

export const LIST_PROBLEM_TYPES = [
  {
    value: "",
    label: "Select Type",
  },
  {
    value: "app_issue",
    label: "App Issue",
  },
  {
    value: "payment_issue",
    label: "Payment Issue",
  },
  {
    value: "account_issue",
    label: "Account Issue",
  },
  {
    value: "other_type",
    label: "other",
  },
];

export const LIST_FAQ_STATUS = [
  {
    value: "",
    label: "Choose Status",
  },
  {
    value: "unsolved",
    label: "Not solved yet",
  },
  {
    value: "pending_confirmation",
    label: "Pending Confirmation",
  },
  {
    value: "solved",
    label: "Solved",
  },
];

export const STEP_STATE_ID_TO_NAME = {
  0: "Pending",
  1: "In progress",
  2: "Paused",
  3: "Depending on other",
  4: "Completed",
};

export const STEP_STATE_NAME_TO_ID = {
  Pending: 0,
  "In progress": 1,
  Paused: 2,
  "Depending on other": 3,
  Completed: 4,
};

export const STEP_TYPE_ID_TO_NAME = {
  1: "Step",
  2: "Scenario",
  3: "Result",
};

export const STEP_TYPE_NAME_TO_ID = {
  Step: 1,
  Scenario: 2,
  Result: 3,
};

export const STEP_ACTION_ID_TO_NAME = {
  0: "Continue",
  1: "Succeed",
  2: "Fail",
  3: "Goto Step",
};

export const STEP_ACTION_NAME_TO_ID = {
  Continue: 0,
  Succeed: 1,
  Fail: 2,
  "Goto Step": 3,
};

export const LOG_TABS_ID_TO_NAME = {
  1: "All logs",
  2: "Logs created by me",
  3: "Logs assigned to me",
  4: "Logs assigned to my department",
  5: "Logs I follow",
  6: "Logs I monitor",
  7: "Logs unassigned",
};

export const LOG_TABS_NAME_TO_ID = {
  "All logs": 1,
  "Logs created by me": 2,
  "Logs assigned to me": 3,
  "Logs assigned to my department": 4,
  "Logs I follow": 5,
  "Logs I monitor": 6,
  "Logs unassigned": 7,
};

export const LOG_BRANCH_ID_TO_NAME = {
  1: "Current Branch", //"Include logs assigned to current branch only"
  2: "Include SubBranches", //"Include logs assigned to current branch and subbranches"
};

export const LOG_BRANCH_NAME_TO_ID = {
  "Current Branch": 1,
  "Include SubBranches": 2,
};

export const LOG_TYPES_NAME_TO_ID = {
  "LOG": 1,
  "TASK": 2,
};

export const LOG_TYPES_ID_TO_NAME = {
  1: "LOG",
  2: "TASK",
};


export const SORT_BY_LIST = [
  {
    label: "Due Date",
    value: 0,
  },
  {
    label: "Log Date",
    value: 1,
  },
  {
    label: "Priority",
    value: 2,
  },/* 
  {
    label: "Remind Date",
    value: 3,
  }, */
  {
    label: "State",
    value: 4,
  },
];

export const DUE_DATE_LIST = [
  {
    label: "All Due Dates",
    value: 0,
  },
  {
    label: "No Due Dates",
    value: 1,
  },
  {
    label: "With Due Dates",
    value: 2,
  },
];

export const BRANCH_LEVEL_NAME_TO_ID = {
  ADMINISTRATOR: 1,
  ROOT: 2,
  BASIC: 3,
  LEAF: 4,
};

export const BRANCH_LEVEL_ID_TO_NAME = {
  1: "ADMINISTRATOR",
  2: "ROOT",
  3: "BASIC",
  4: "LEAF",
};