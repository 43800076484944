import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {
  authenticate as authenticateService,
  logout as logoutService,
} from "services/common/authenticationServices";

const AuthenticationContext = createContext();

export function AuthenticationContextProvider({ children }) {
  //CUSTOM CLASSES
  const navigate = useNavigate();
  const location = useLocation();
  //PERMISSIONS
  //STATE
  const [authenticated, setAuthenticated] = useState("loading");
  const [user, setUser] = useState("loading");
  const LOCAL_LOGIN_PAGE_REGEX = /^\/auth\/login\/[^/]+$/;
  const ADMIN_PAGE_REGEX = /^\/admin\/.*/;
  //FUNCTIONS
  const authenticate = async (source) => {
    if (location.pathname === "/changePassword") return;
    try {
      const response = await authenticateService(source?.token);
      if (response.success && response.statusCode === 200) {
        updateAuthentication(true, response.data);
      } else throw new Error("User Unauthenticated!");
    } catch (error) {
      console.log("Failed to execute authentication method", error);
      updateAuthentication(false);
    }
  }
  const logout = async () => {
    try {
      const response = await logoutService();
      if (response.success && response.statusCode === 200) {
        updateAuthentication(false);
      } else throw new Error("User failed to logout!");
    } catch (error) {
      console.log("Failed to logout user", error);
      toast.error("Failed to logout user!");
    }
  }
  const updateAuthentication = (newAuthenticated, user) => {
    if (newAuthenticated) {
      setAuthenticated(true);
      setUser(user);
    } else {
      setAuthenticated(false);
      setUser(null);
    };
  }
  //RENDER LOGIC
  useEffect(() => {
    const source = axios.CancelToken.source();
    const rerunAuthentication = async () => {
      console.log("AUTHENTICATION RUNNING FROM REPEATING FUNCTION");
      authenticate(source);
    };

    const intervalId = setInterval(rerunAuthentication, 180000);

    function handleNotAuthenticated() {
      updateAuthentication(false);
    }

    document.addEventListener("not-authenticated", handleNotAuthenticated);

    authenticate(source);

    return () => {
      document.removeEventListener("not-authenticated", handleNotAuthenticated);
      source.cancel("Component unmounted");
      clearInterval(intervalId);
    };
  }, []);
  //CONSTANTS

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        user,
        logout,
        updateAuthentication,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}
