import { post } from "./basicServices";

export async function authenticate(cancelToken) {
  return post("users/authenticate", null, null, cancelToken);
}

export async function generateToken(cancelToken) {
  return post("users/generateToken", null, null, cancelToken);
}

export  async function logout() {
  return post("users/logout");
}
